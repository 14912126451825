// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-cater-js": () => import("./../../../src/pages/cater.js" /* webpackChunkName: "component---src-pages-cater-js" */),
  "component---src-pages-decor-js": () => import("./../../../src/pages/decor.js" /* webpackChunkName: "component---src-pages-decor-js" */),
  "component---src-pages-entertainment-js": () => import("./../../../src/pages/entertainment.js" /* webpackChunkName: "component---src-pages-entertainment-js" */),
  "component---src-pages-frequently-asked-questions-js": () => import("./../../../src/pages/frequently-asked-questions.js" /* webpackChunkName: "component---src-pages-frequently-asked-questions-js" */),
  "component---src-pages-health-and-safety-js": () => import("./../../../src/pages/health-and-safety.js" /* webpackChunkName: "component---src-pages-health-and-safety-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-production-js": () => import("./../../../src/pages/production.js" /* webpackChunkName: "component---src-pages-production-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-category-sub-page-js": () => import("./../../../src/templates/categorySubPage.js" /* webpackChunkName: "component---src-templates-category-sub-page-js" */)
}

